import { Injectable } from '@angular/core';
import { Socket } from 'ngx-socket-io';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SocketService {

  alertsEvent: Observable<any>;
  constructor(private socket: Socket) { }

  /**
   * Returns an observable containing 'alerts' listener
   */
  getAlerts(): Observable<any> {
    // if alertsEvent has not been initialized
    if(!this.alertsEvent) {
      // then initialize and listen for 'alerts' event topic
      this.alertsEvent = this.socket.fromEvent<any>('alerts');
    }
    return this.alertsEvent;
  }


  /**
   * Emits a value for a topic
   */
  emit(topic: string, value: any) {
    this.socket.emit(topic, value);
  }
}
