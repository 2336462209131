import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { NavigationEnd, Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  logged: string = 'false';
  user: any = {};
  token: string = '';

  constructor(private http: HttpClient, private router: Router) {
    router.events.subscribe((val) => {
      this.token = localStorage.getItem('token');
      if (val instanceof NavigationEnd) {
        if (this.token == '') {
          this.token = '';
          this.router.navigateByUrl('/pages/login');
        }
      }
    });
  }

  login(user) {
    return this.http.post(`
      ${environment.api.endpoint}/${environment.api.currentVersion}/users/login`
    , {
      ...user,
    });
  }

  logout() {
    localStorage.setItem('token', '');
    this.token = localStorage.getItem('token');
    this.router.navigateByUrl('/pages/login');
  }

  setLoggedUser(token) {
    if (token) {
      localStorage.setItem('token', token);
      this.token = localStorage.getItem('token');
      this.user = {username: 'Alex'};
      this.router.navigateByUrl('/pages/dashboard');
    } else {
      localStorage.setItem('token', '');
      this.token = localStorage.getItem('token');
      this.router.navigateByUrl('/pages/login');
    }
  }

}
