import { UserService } from './../services/userService';
import {
    HttpEvent,
    HttpInterceptor,
    HttpHandler,
    HttpRequest,
    HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { NotifierService } from '../services/notifier.service';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class HttpErrorInterceptor implements HttpInterceptor {
    
    constructor(private notifierService: NotifierService, private userService: UserService) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request)
        .pipe(
            retry(1),
            catchError((error: HttpErrorResponse) => {
                console.log(error);
                let errorMessage = '';
                // console.log(errorMessage);
                errorMessage = `Error code: ${error.status}. Http error message: ${error.message}`;
                this.notifierService.show(error.error.message, 'Errore', 'danger');
                if(error.error.extra == "Missing authentication token" || error.error.extra == "Expired or wrong token"){
                    this.userService.setLoggedUser('');
                }
                return throwError(errorMessage);
            })
        )
}
}